import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ParentPayload } from '@payloads/parent.payload';

@Injectable({
  providedIn: 'root',
})
export class ParentService {
  constructor(private httpClient: HttpClient) {}

  createBillingData(payload: ParentPayload): any {
    return this.httpClient.put(
      `${environment.API}/invoice-addresses`,
      payload.createBillingInfo()
    );
  }

  getProfile(): any {
    return this.httpClient.get(`${environment.API}/parents/profile`);
  }

  getProfilingSurvey(): any {
    return this.httpClient.get(`${environment.API}/quiz/profiling`);
  }

  updateParent(payload: ParentPayload): any {
    return this.httpClient.put(
      `${environment.API}/parents/profile`,
      payload.updatePersonalInfo()
    );
  }

  updateParentInfoAdd(parent_id: string, payload: any): any {
    return this.httpClient.post(
      `https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/saveInfoUsersTest?parentId=${parent_id}`,
      payload
    );
  }

  saveProfilingSurvey(payload): any {
    return this.httpClient.post(`${environment.API}/quiz/profiling`, payload);
  }

  getInfoStepsEnrollment(): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`
    );
  }

  validationEnrollmentProcess(user_id: string, child_id): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/processFlowUsers?userId=${user_id}&childId=${child_id}`
    );
  }

  processStepEnrollmentConfirmated(payload: any): any {
    return this.httpClient.post(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`,
      payload
    );
  }

  setIdentityDocuments(payload: FormData): any {
    return this.httpClient.put(
      `${environment.API}/parent-identity-documents`,
      payload
    );
  }

  getInfoParent2(parent_id: string): any {
    return this.httpClient.get(
      `https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/saveInfoUsersTest?parentId=` +
        parent_id
    );
  }
}
